import { AD, BD, C, CS, E, FH, NB, NC, ND, NI, NO, PD, RC } from "../../utils/data";
import React, { useReducer, useRef, useState } from 'react';
import { Page, Text, View, Document, StyleSheet, PDFViewer, Image } from '@react-pdf/renderer';
import rawLogo from '../../assets/svg/rawLogo.png';
import Logo from "../../assets/svg/Logo";
import { TextField } from "@mui/material";
import SignatureCanvas from 'react-signature-canvas';

// Create styles
const styles = StyleSheet.create({
  page: {
    padding: '1cm'
  },
  section: {
    
  },
  header: {
    fontSize: '16px',
  },
  subheader: {
    fontSize: '12px',
  },
  image: {
    width: '25%',
  },
  topofpage: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  entry: {
    fontSize: '10px',
  },
  signaturebox: {
    border: '1px dashed black',
  }
});

// Create Document Component
const MyDocument = ({values, name, signature}:any) => {
    const ni = values.ni.filter((v:any)=>v.checked);
    const nc = values.nc.filter((v:any)=>v.checked);
    const nb = values.nb.filter((v:any)=>v.checked);
    const no = values.no.filter((v:any)=>v.checked);
    const nd = values.nd.filter((v:any)=>v.checked);
    const e = values.e.filter((v:any)=>v.checked);
    const c = values.c.filter((v:any)=>v.checked);
    const rc = values.rc.filter((v:any)=>v.checked);
    const fh = values.fh.filter((v:any)=>v.checked);
    const ad = values.ad.filter((v:any)=>v.checked);
    const bd = values.bd.filter((v:any)=>v.checked);
    const pd = values.pd.filter((v:any)=>v.checked);
    const cs = values.cs.filter((v:any)=>v.checked);
    {/*
            ni,nc,nb,no,
nd,e,c,rc,
fh,ad,bd,pd,cs*/}
    return (
        <Document>
            <Page size="LETTER" style={styles.page}>
                <View style={styles.topofpage}>
                    <View style={styles.image}>
                        <Image src={rawLogo} />
                    </View>
                </View>
            <View style={styles.section}>
                <Text style={styles.header}>Assessment Report For {name}</Text>
            </View>
            <View style={styles.section}>
                {ni.length>0 && <Text style={styles.subheader}>INTAKE</Text>}
                {ni.map((v:any)=>(
                    <View>
                        <Text style={styles.entry}>{v.label}</Text>
                    </View>
                ))}
            </View>
            <View style={styles.section}>
                {nc.length>0 && <Text style={styles.subheader}>CLINICAL</Text>}
                {nc.map((v:any)=>(
                    <View>
                        <Text style={styles.entry}>{v.label}</Text>
                    </View>
                ))}
            </View>
            <View style={styles.section}>
                {nb.length>0 && <Text style={styles.subheader}>BEHAVIORAL-ENVIRONMENTAL</Text>}
                {nb.map((v:any)=>(
                    <View>
                        <Text style={styles.entry}>{v.label}</Text>
                    </View>
                ))}
            </View>
            <View style={styles.section}>
                {no.length>0 && <Text style={styles.subheader}>OTHER</Text>}
                {no.map((v:any)=>(
                    <View>
                        <Text style={styles.entry}>{v.label}</Text>
                    </View>
                ))}
            </View>
            <View style={styles.section}>
                {nd.length>0 && <Text style={styles.subheader}>FOOD AND/OR NUTRIENT DELIVERY</Text>}
                {nd.map((v:any)=>(
                    <View>
                        <Text style={styles.entry}>{v.label}</Text>
                    </View>
                ))}
            </View>
            <View style={styles.section}>
                {e.length>0 && <Text style={styles.subheader}>NUTRITION EDUCATION</Text>}
                {e.map((v:any)=>(
                    <View>
                        <Text style={styles.entry}>{v.label}</Text>
                    </View>
                ))}
            </View>
            <View style={styles.section}>
                {c.length>0 && <Text style={styles.subheader}>NUTRITION COUNSELING</Text>}
                {c.map((v:any)=>(
                    <View>
                        <Text style={styles.entry}>{v.label}</Text>
                    </View>
                ))}
            </View>
            <View style={styles.section}>
                {rc.length>0 && <Text style={styles.subheader}>COORDINATION OF NUTRITION CARE BY A NUTRITION PROFESSIONAL</Text>}
                {rc.map((v:any)=>(
                    <View>
                        <Text style={styles.entry}>{v.label}</Text>
                    </View>
                ))}
            </View>
            <View style={styles.section}>
                {fh.length>0 && <Text style={styles.subheader}>FOOD/NUTRITION-RELATED HISTORY</Text>}
                {fh.map((v:any)=>(
                    <View>
                        <Text style={styles.entry}>{v.label}</Text>
                    </View>
                ))}
            </View>
            <View style={styles.section}>
                {ad.length>0 && <Text style={styles.subheader}>ANTHROPOMETRIC MEASUREMENTS</Text>}
                {ad.map((v:any)=>(
                    <View>
                        <Text style={styles.entry}>{v.label}</Text>
                    </View>
                ))}
            </View>
            <View style={styles.section}>
                {bd.length>0 && <Text style={styles.subheader}>BIOCHEMICAL DATA, MEDICAL TESTS AND PROCEDURES</Text>}
                {bd.map((v:any)=>(
                    <View>
                        <Text style={styles.entry}>{v.label}</Text>
                    </View>
                ))}
            </View>
            <View style={styles.section}>
                {pd.length>0 && <Text style={styles.subheader}>NUTRITION-FOCUS PHYSICAL FINDINGS</Text>}
                {pd.map((v:any)=>(
                    <View>
                        <Text style={styles.entry}>{v.label}</Text>
                    </View>
                ))}
            </View>
            <View style={styles.section}>
                {cs.length>0 && <Text style={styles.subheader}>COMPARATIVE STANDARDS</Text>}
                {cs.map((v:any)=>(
                    <View>
                        <Text style={styles.entry}>{v.label}</Text>
                    </View>
                ))}
            </View>
            <View style={{display: 'flex', flexDirection: 'row-reverse'}}>
                <View style={{width: '50%'}}>
                    <Text style={styles.entry}>Signature</Text>
                    <View style={styles.signaturebox}>
                        {signature && (<Image src={signature} />)}
                    </View>
                </View>
            </View>
            {/*
            ni,nc,nb,no,
nd,e,c,rc,
fh,ad,bd,pd,cs*/}
            <View>
                
            </View>
            </Page>
        </Document>
    )
};

const initialState = {
    ad: Object.keys(AD).map((fhkey) => ({ label: AD[fhkey], ncpt: fhkey, checked: false })),
    bd: Object.keys(BD).map((fhkey) => ({ label: BD[fhkey], ncpt: fhkey, checked: false })),
    c: Object.keys(C).map((fhkey) => ({ label: C[fhkey], ncpt: fhkey, checked: false })),
    cs: Object.keys(CS).map((fhkey) => ({ label: CS[fhkey], ncpt: fhkey, checked: false })),
    e: Object.keys(E).map((fhkey) => ({ label: E[fhkey], ncpt: fhkey, checked: false })),
    fh: Object.keys(FH).map((fhkey) => ({ label: FH[fhkey], ncpt: fhkey, checked: false })),
    nb: Object.keys(NB).map((fhkey) => ({ label: NB[fhkey], ncpt: fhkey, checked: false })),
    nc: Object.keys(NC).map((fhkey) => ({ label: NC[fhkey], ncpt: fhkey, checked: false })),
    nd: Object.keys(ND).map((fhkey) => ({ label: ND[fhkey], ncpt: fhkey, checked: false })),
    ni: Object.keys(NI).map((fhkey) => ({ label: NI[fhkey], ncpt: fhkey, checked: false })),
    no: Object.keys(NO).map((fhkey) => ({ label: NO[fhkey], ncpt: fhkey, checked: false })),
    pd: Object.keys(PD).map((fhkey) => ({ label: PD[fhkey], ncpt: fhkey, checked: false })),
    rc: Object.keys(RC).map((fhkey) => ({ label: RC[fhkey], ncpt: fhkey, checked: false })),
};

const reducer = (state:any, action:any) => {
    switch (action.type) {
        case 'TOGGLE_CHECK':
            return {
                ...state,
                [action.field]: state[action.field].map((item:any) =>
                    item.ncpt === action.ncpt ? { ...item, checked: action.checked } : item
                )
            };
        default:
            return state;
    }
};

const AdminReportPage = () => {
    const [name, setName] = useState("Bobby Fischer");
    const [signature, setSignature] = useState(null) as any;
    let sigCanvasRef = useRef<any>(null) as any;
    const [state, dispatch] = useReducer(reducer, initialState);
    const handleCheck = (type: string, ncpt: any) => (v:any) => {
        dispatch({type: 'TOGGLE_CHECK', field: type, ncpt: ncpt, checked: v.target.checked});
    }
    const handleName = (v:any) => {
        setName(v.target.value);
    }
    const v = false;
    return (
        <div style={{marginTop: '1cm'}}>
            <TextField label="Patient Name" value={name} onChange={handleName}/>
            <h1>Diagnostic</h1>
            <h2>INTAKE</h2>
            <div style={v?{display: 'flex', flexWrap: 'wrap', gap: '32px'}:{display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: '32px'}}>
                {state.ni.map((v:any)=>(<label key={v.ncpt} style={{}}><input type="checkbox" checked={v.checked} onChange={handleCheck('ni', v.ncpt)}/>{v.label}</label>))}
            </div>
            <h2>CLINICAL</h2>
            <div style={v?{display: 'flex', flexWrap: 'wrap', gap: '32px'}:{display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: '32px'}}>
                {state.nc.map((v:any)=>(<label key={v.ncpt} style={{}}><input type="checkbox" checked={v.checked} onChange={handleCheck('nc', v.ncpt)}/>{v.label}</label>))}
            </div>
            <h2>BEHAVIORAL-ENVIRONMENTAL</h2>
            <div style={v?{display: 'flex', flexWrap: 'wrap', gap: '32px'}:{display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: '32px'}}>
                {state.nb.map((v:any)=>(<label key={v.ncpt} style={{}}><input type="checkbox" checked={v.checked} onChange={handleCheck('nb', v.ncpt)}/>{v.label}</label>))}
            </div>
            <h2>Other</h2>
            <div style={v?{display: 'flex', flexWrap: 'wrap', gap: '32px'}:{display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: '32px'}}>
                {state.no.map((v:any)=>(<label key={v.ncpt} style={{}}><input type="checkbox" checked={v.checked} onChange={handleCheck('no', v.ncpt)}/>{v.label}</label>))}
            </div>
            <h1>Intervention</h1>
            <h2>FOOD AND/OR NUTRIENT DELIVERY</h2>
            <div style={v?{display: 'flex', flexWrap: 'wrap', gap: '32px'}:{display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: '32px'}}>
                {state.nd.map((v:any)=>(<label key={v.ncpt} style={{}}><input type="checkbox" checked={v.checked} onChange={handleCheck('nd', v.ncpt)}/>{v.label}</label>))}
            </div>
            <h2>NUTRITION EDUCATION</h2>
            <div style={v?{display: 'flex', flexWrap: 'wrap', gap: '32px'}:{display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: '32px'}}>
                {state.e.map((v:any)=>(<label key={v.ncpt} style={{}}><input type="checkbox" checked={v.checked} onChange={handleCheck('e', v.ncpt)}/>{v.label}</label>))}
            </div>
            <h2>NUTRITION COUNSELING</h2>
            <div style={v?{display: 'flex', flexWrap: 'wrap', gap: '32px'}:{display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: '32px'}}>
                {state.c.map((v:any)=>(<label key={v.ncpt} style={{}}><input type="checkbox" checked={v.checked} onChange={handleCheck('c', v.ncpt)}/>{v.label}</label>))}
            </div>
            <h2>COORDINATION OF NUTRITION CARE BY A NUTRITION PROFESSIONAL</h2>
            <div style={v?{display: 'flex', flexWrap: 'wrap', gap: '32px'}:{display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: '32px'}}>
                {state.rc.map((v:any)=>(<label key={v.ncpt} style={{}}><input type="checkbox" checked={v.checked} onChange={handleCheck('rc', v.ncpt)}/>{v.label}</label>))}
            </div>
            <h1>Monitoring and Evaluation</h1>
            <h2>FOOD/NUTRITION-RELATED HISTORY</h2>
            <div style={v?{display: 'flex', flexWrap: 'wrap', gap: '32px'}:{display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: '32px'}}>
                {state.fh.map((v:any)=>(<label key={v.ncpt} style={{}}><input type="checkbox" checked={v.checked} onChange={handleCheck('fh', v.ncpt)}/>{v.label}</label>))}
            </div>
            <h2>ANTHROPOMETRIC MEASUREMENTS (AD)</h2>
            <div style={v?{display: 'flex', flexWrap: 'wrap', gap: '32px'}:{display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: '32px'}}>
                {state.ad.map((v:any)=>(<label key={v.ncpt} style={{}}><input type="checkbox" checked={v.checked} onChange={handleCheck('ad', v.ncpt)}/>{v.label}</label>))}
            </div>
            <h2>BIOCHEMICAL DATA, MEDICAL TESTS AND PROCEDURES (BD)</h2>
            <div style={v?{display: 'flex', flexWrap: 'wrap', gap: '32px'}:{display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: '32px'}}>
                {state.bd.map((v:any)=>(<label key={v.ncpt} style={{}}><input type="checkbox" checked={v.checked} onChange={handleCheck('bd', v.ncpt)}/>{v.label}</label>))}
            </div>
            <h2>NUTRITION-FOCUS PHYSICAL FINDINGS (PD)</h2>
            <div style={v?{display: 'flex', flexWrap: 'wrap', gap: '32px'}:{display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: '32px'}}>
                {state.pd.map((v:any)=>(<label key={v.ncpt} style={{}}><input type="checkbox" checked={v.checked} onChange={handleCheck('pd', v.ncpt)}/>{v.label}</label>))}
            </div>
            <h2>COMPARATIVE STANDARDS (CS)</h2>
            <div style={v?{display: 'flex', flexWrap: 'wrap', gap: '32px'}:{display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: '32px'}}>
                {state.cs.map((v:any)=>(<label key={v.ncpt} style={{}}><input type="checkbox" checked={v.checked} onChange={handleCheck('cs', v.ncpt)}/>{v.label}</label>))}
            </div>
            <div style={{display: 'flex', flexDirection: 'row-reverse', marginRight: '1cm'}}>
                <div>
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                        <p>Signature</p>
                        <button onClick={()=>{sigCanvasRef.current.clear()}}>Clear</button>
                        <button onClick={()=>{
                            setSignature(sigCanvasRef.current.toDataURL());
                        }}>Accept Signature and Sign Document</button>
                    </div>
                    <SignatureCanvas ref={sigCanvasRef} penColor='green'
                    canvasProps={{width: 500, height: 200, className: 'sigCanvas', style:{border: '1px solid black', boxShadow: '0px 0px 2px 1px gray'}}}/></div></div>
            <PDFViewer style={{width: '100%', minHeight: '900px'}}>
                <MyDocument values={state} name={name} signature={signature}/>
            </PDFViewer>
        </div>
    )
};

export default AdminReportPage;